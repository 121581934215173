import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">社宅公共藝術咖啡桌 / 第一輪</h2>
          <h1 className="main_title">#城市策略</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_7">
              <figure className="figure">
                <img src={require("../../images/blog/post_2_4_2.jpg")} alt=""/>
                <figcaption className="figcaption left">組長：林宜珍<br/>組員：吳俊毅、邱俊達、景雅琦</figcaption>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">

                <p>成員首先分享各自對城市策略的想法，匯整出下面幾個面向：</p>

                <h5>1. 社宅公共藝術可能產生都市調節與補償的契機</h5>
                <p>他們分享一個實際執行的案例：規劃住宅時，將周遭自然環境引入社區大樓的一樓並對外開放，外部的人來這有如走入自然空間。這引發一種想像：都市建設的過程奪走了很多自然環境生態，我們能否在建設過程中產生一種補償機制，盡到都市發展的責任，將自然償還回來，這回應了子議題提到的城市保育。</p>
                <p>城市策略上，社宅的公共藝術行動若能發展出補償策略，其範圍不應侷限在社宅基地，更要關照周邊鄰里，讓社宅與周邊社區融合，消彌內外族群的界線。</p>

                <h5>2. 城市意象的塑造與公共藝術的關係</h5>
                <p>傳統公共藝術被看作是形塑城市意象的指標，但是，現階段比起物質性表達，更重視生產過程。是否有政策或機制來協助在地民眾提早參與公共藝術的生產，而非只是完成的作品或藝術家定好的計畫。</p>
                <p>除了藝術品，知識生產的過程同等重要。參與社宅公共藝術的過程，可以挖掘城市過去的歷史、背景，產生新的身分認同，完成的作品跟地方會產生自明性。不同的基地與案例，將發展出差異與多元化，回應了都市現實的多元面貌。</p>
                <p>綜合以上契機，看似硬體的城市策略討論下，社宅公共藝術更涉及我們想形塑何種新的「都市人」、「臺北人」策略。</p>

              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_2_5" className="next_page">
            <div className="next_title">#回饋與討論</div>
            <div className="next_owner">社宅公共藝術咖啡桌 / 第一輪</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
